<template>
  <TitleCoaching :title="title" />
  <Calendar :legends="[{color: 'var(--color--dark-grey)', text:'Indisponibilités'}, {color: 'var(--color--pink)', text:'RDV programmés'}]" />
</template>

<script>
import Calendar from "@/components/Calendar/Calendar";
import TitleCoaching from "@/components/Coaching/TitleCoaching";
export default {
  name: "CalendarView",
  components: {TitleCoaching, Calendar},
  data() {
    return {
      title: 'Calendrier'
    }
  }
}
</script>