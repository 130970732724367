import {CancelToken, default as apiAxiosInstance} from "@/services/axios/api.axios";
import apiHelper from "@/helpers/api";
import moment from "moment";
import TokenService from "@/services/token.service";

var cancel;

class CalendarService {
    getDefinedSlots(from, to) {
        if (cancel != undefined) {
            cancel('Cancel last request');
        }

        return apiAxiosInstance.get(apiHelper.calendarsEndpoint, {
            params: {
                from_date: (from < moment() && TokenService.isRoleUserAndIsAnonymousIdentified() ? moment() : from ).format("YYYY-MM-DD"),
                to_date: to.format("YYYY-MM-DD"),
            },
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });
    }
}

export default new CalendarService();