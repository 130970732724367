<template>
  <div v-if="!consumptionReached">
    <div class="calendar-container">
      <div class="calendar">
        <button class="btn-prev" v-on:click="prevSlots"><img src="@/assets/images/chevron.svg"></button>
        <Day v-for="day in days" :date="day" :definedSlots="definedSlots" :key="day"></Day>
        <button class="btn-next" v-on:click="nextSlots"><img src="@/assets/images/chevron.svg"></button>
      </div>
    </div>
    <div class="legends">
      <Legend v-for="(legend, index) in legends" :color="legend.color" :text="legend.text" :key="index"/>
    </div>
  </div>
  <div v-else class="notfound">
    <h3 class="text-center">Ne me dites pas que c'est fini ?</h3>
    <p>Vous en voulez encore ? <br />
      Écrivez nous sur <a href="mailto:contact@ekkooo.com">contact@ekkooo.com</a>, on verra ce qu'on peut faire ☺️
      </p>
  </div>
</template>

<script>
import moment from "moment";
import Day from "@/components/Calendar/Day";
import Legend from "@/components/Calendar/Legend";
import calendarService from '@/services/calendar.service'

export default {
  name: "Calendar",
  props: ['legends'],
  data() {
    return {
      days: [],
      definedSlots: [],
      windowWidth: window.innerWidth,
      consumptionReached: false
    }
  },
  beforeMount() {
    this.$store.commit('meetingStore/updateMeetingDate', '')

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    for (let i = 0; i < this.numberOfDay; i++) {
      this.days.push(moment().startOf('week').add(i, 'days'))
    }

    this.updateDefinedSlots();
  },
  computed: {
    numberOfDay() {
      switch(true) {
        case this.windowWidth <= 400:
          return 2
        case this.windowWidth <= 500:
          return 3
        case this.windowWidth <= 700:
          return 4
        case this.windowWidth <= 900:
          return 5
        default:
          return 7
      }
    }
  },
  components: {
    Day, Legend
  },
  methods: {
    nextSlots() {
      var lastDay = this.days[this.days.length - 1]
      this.days = []

      for (let i = 1; i <= this.numberOfDay; i++) {
        this.days.push(moment(lastDay).add(i, 'days'))
      }

      this.updateDefinedSlots()
    },
    prevSlots() {
      var firstDay = this.days[0];
      this.days = [];

      for (let i = this.numberOfDay; i >= 1; i--) {
        this.days.push(moment(firstDay).subtract(i, 'days'))
      }

      this.updateDefinedSlots()
    },
    updateDefinedSlots() {
      calendarService.getDefinedSlots(this.days[0], this.days[this.numberOfDay - 1])
          .then((response) => {
            this.definedSlots = response.data.data.calendar_time_slots
          }).catch((e) => {
            if(e.response.status === 404 && e.response.data.data.user === 'Consumption is reached') {
              this.consumptionReached = true
              this.$emit('update-title', 'Oups, plus de rendez-vous dispo !')
            }
      });
    }
  }
}
</script>