<template>
  <div class="day">
    <div class="day-name"><strong>{{ dayName }}</strong><br/>{{ month }}</div>
    <div>
        <TimeSlot v-for="slot in slots" :key="slot" :currentSlot="slot" :definedSlots="definedSlots"></TimeSlot>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import TimeSlot from "@/components/Calendar/TimeSlot";

export default {
  name: 'Day',
  props: ['date', 'definedSlots'],
  components: {TimeSlot},
  computed: {
    dayName() {
      return this.date.format('dddd')
    },
    month() {
      return this.date.format('Do MMM')
    },
    slots() {
      var slots = [];
      for (let i = 0; i < 16; i++) {
        slots.push(
            moment(this.date).set({"hour": 8, "minute": 0, "second": 0}).add(i * 45, 'minutes')
        );
      }

      return slots;
    }
  }
}
</script>