<template>
  <div v-on="isAvailable || isMeeting ? { click: clickSlot } : {}" class="slot" :class="{'slot-available': isAvailable, 'slot-meeting': isMeeting, 'slot-unavailable': isUnavailable}">
    <template v-if="isAvailable || isMeeting || isUnavailable">{{ currentSlot.local().format('HH:mm') }}</template>
    <svg v-else width="13" height="4" viewBox="0 0 13 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="13" height="4" rx="2" fill="#A8AFBC"/>
    </svg>
    <span class="precedent-coach" v-if="isPrecedentCoach && isAvailable"></span>
  </div>
</template>

<script>
export default {
  name: 'TimeSlot',
  props: ['currentSlot', 'definedSlots'],
  methods: {
    clickSlot() {
      if (this.isAvailable) {
        this.$store.commit('meetingStore/updateMeetingDate', this.currentSlot.format())
        this.$router.push({name: 'meetingValidation'})
      }

      if (this.isMeeting) {
        this.$router.push({name: 'MeetingDetail', params: {id: this.definedSlot.meeting}})
      }
    }
  },
  computed: {
    definedSlot() {
      return this.definedSlots.find(x => x.starts_at === this.currentSlot.utc().format())
    },
    isAvailable() {
      return !!this.definedSlot && !this.isUnavailable && !this.isMeeting;
    },
    isUnavailable() {
      return !!(this.definedSlot && this.$store.state.user.profile.roles.includes('ROLE_COACH') && !this.definedSlot.meeting);
    },
    isMeeting() {
      return !!(this.definedSlot && this.definedSlot.meeting);
    },
    isPrecedentCoach() {
      return !!(this.definedSlot && this.definedSlot.with_last_coach);
    }
  }
}
</script>
