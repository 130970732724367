<template>
  <span class="legend">
    <i :style="{ backgroundColor: color }" />{{text}}
  </span>
</template>

<script>
export default {
  name: "Legend",
  props:['color', 'text']
}
</script>